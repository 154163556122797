import { Stack, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAnnotationContext } from "../../contexts/annotationContext";
import ButtonModify from "../common/buttonModify";
import ButtonValidate from "../common/buttonValidate";
import { useState } from "react";
import { useMainContext } from "../../contexts/mainContext";
import { FilesService } from "../../client";

const AnnotationButtons = () => {
  const { t } = useTranslation();
  const { saveandnext, handleAddObservation, annotationButtonDisabled } = useAnnotationContext();

  return (
    <Stack direction="row" justifyContent="space-between" height="auto">
      <Stack direction="row" justifyContent="flex-start" spacing={2}>
        <ButtonModify
          disabled={ annotationButtonDisabled }
          content={capitalize(t("observations.new"))}
          edit={() => handleAddObservation()}
          startIcon="add"
        />
      </Stack>
      <Stack justifyContent="flex-end">
        <ButtonValidate
          disabled={ annotationButtonDisabled }
          content={capitalize(t("main.save_and_continue"))}
          validate={() => saveandnext()}
        />
      </Stack>
    </Stack>
  );
};

export default AnnotationButtons;
